import { axios, toCamelCase } from '@utils';
import userService from '@services/user.service';

const {
  removeAvatar, updateNotificationSettings, notificationSettings, getUserNavData,
} = userService;

async function updateUser(userObj) {
  const user = await userService.updateUser(userObj);
  return user;
}

async function getUser() {
  const user = await userService.getUser();
  const {
    openingsContactDetailsFirstName,
    openingsContactDetailsLastName,
    openingsContactDetailsEmail,
    openingsContactDetailsPhoneNumber,
    openingsContactDetailsTitle,
    ...rest
  } = user;
  return {
    ...rest,
    openingsContactDetails: {
      firstName: openingsContactDetailsFirstName,
      lastName: openingsContactDetailsLastName,
      email: openingsContactDetailsEmail,
      phoneNumber: openingsContactDetailsPhoneNumber,
      title: openingsContactDetailsTitle,
    },
  };
}

async function getActionItems() {
  const { data } = await axios.get('/api/v1/legup/users/provider_action_items');

  return toCamelCase(data);
}

async function confirmNewFeeStructure(providerId) {
  const response = await axios.put(`/api/v1/providers/${providerId}/confirm_new_fee_structure`);
  return toCamelCase(response.data);
}

export {
  removeAvatar, updateNotificationSettings, getUser, updateUser, notificationSettings,
  getUserNavData, getActionItems, confirmNewFeeStructure,
};
