import { axios, toCamelCase } from '@utils';

const FACILITY_OPINIONS = {
  favorited: 'great_fit',
  ignored: 'not_a_fit',
  saved: 'maybe',
  noOpinion: null,
};

async function fetchFacilities({ idsToRequest, ...restParams }, { isSearch, limited } = {}) {
  const axiosController = new AbortController();
  try {
    if (isSearch) {
      axiosController.abort();
    }

    const params = {
      facility_ids: idsToRequest,
      ...restParams,
    };

    const url = limited ? '/childcare/facilities_limited.json' : '/childcare/facilities.json';
    const response = await axios.get(url, { params, signal: axiosController.signal });
    return response.data.facilities;
  } catch (e) {
    if (axiosController.abort()) {
      // eslint-disable-next-line
      console.info('facilities/loadFacilityRecords request cancelled', e.message);
      return [];
    }
    // eslint-disable-next-line
    console.error(e);
    throw new Error('Could not fetch facilities');
  }
}

async function fetchMatchesFacilitiesForParent() {
  const response = await axios.get('/api/v1/matches.json');
  return response.data;
}

async function acceptPayment(facilityId, facilityAcceptPayment) {
  const response = await axios.post(`api/v1/child_care_facilities/${facilityId}/accept_payment.json`, {
    accept_payment: facilityAcceptPayment,
  });
  return toCamelCase(response.data);
}

async function deactivateFacilitiesPayment() {
  const response = await axios.post('/api/v1/child_care_facilities/deactivate_all_payment.json');
  return toCamelCase(response.data);
}

async function fetchFacilitiesByOpinion(opinion) {
  const response = await axios.get('/childcare/markers.json', {
    params: { opinion_only: FACILITY_OPINIONS[opinion], sort: 'quality' },
  });
  return {
    ids: response.data.ordered_ids.map(Number),
    facilities: toCamelCase(response.data.markers.map((m) => m.facility)),
  };
}

async function fetchOpinionCounts() {
  const { data } = await axios.get('/facility_opinions/counts.json');
  return {
    favorited: data[FACILITY_OPINIONS.favorited],
    saved: data[FACILITY_OPINIONS.saved],
    ignored: data[FACILITY_OPINIONS.ignored],
  };
}

function saveFacilityOpinion(facilityId, opinion, context) {
  return axios.put(`/child_care_facilities/${facilityId}/opinion.json`, {
    opinion,
    context,
  });
}

async function searchFacilities({ query, page = 1, perPage = 10 }) {
  const response = await axios.get('/childcare/payments_list.json', { params: { search: query, page, per_page: perPage } });
  return response.data;
}

function saveFacilityComment(facilityId, comment) {
  return axios.put(`/child_care_facilities/${facilityId}/comment.json`, { comment });
}

function saveFacilityImages(facilityId, facilityData) {
  return axios.post('/facility_logs.json', {
    child_care_facility_id: facilityId,
    facility_log: facilityData,
  });
}

async function fetchFacilitiesForProvider({
  providerId, page = 1, search = null, messaging,
}) {
  const BASE_URL = '/api/v1/child_care_facilities';
  const response = await axios.get(`${BASE_URL}/${providerId}/facilities_by_provider.json`, {
    params: {
      page,
      search,
      messaging,
    },
  });
  return toCamelCase(response.data);
}

export {
  FACILITY_OPINIONS,
  fetchFacilities,
  fetchFacilitiesByOpinion,
  fetchOpinionCounts,
  fetchFacilitiesForProvider,
  fetchMatchesFacilitiesForParent,
  saveFacilityOpinion,
  saveFacilityComment,
  searchFacilities,
  saveFacilityImages,
  acceptPayment,
  deactivateFacilitiesPayment,
};
