import { axios, toCamelCase } from '@utils';
import moment from 'moment-timezone';

async function fetchParentSlots(timezone = 'America/Los_Angeles') {
  const { data } = await axios.get('/parent_availability_slots.json');
  return toCamelCase(data).map(({
    date, from, to, id,
  }) => ({
    date,
    id,
    time: {
      from: moment(new Date(from).toLocaleString('en-US', { timeZone: timezone })).format('h:mm a'),
      to: moment(new Date(to).toLocaleString('en-US', { timeZone: timezone })).format('h:mm a'),
    },
  }));
}

async function updateParentSlots(slots) {
  await axios.put('/parent_availability_slots.json', {
    slots,
  });
  return fetchParentSlots();
}

let fetchToursPromise;
async function fetchTours(facilityId, onlyUpcoming) {
  if (fetchToursPromise) {
    return fetchToursPromise;
  }

  const promise = axios.get('/tour_reservations.json', {
    params: {
      upcoming: onlyUpcoming,
      facility_id: facilityId,
    },
  })
    .then(({ data }) => {
      fetchToursPromise = null;
      return toCamelCase(data.reservations);
    })
    .catch(() => {
      fetchToursPromise = null;
    });
  fetchToursPromise = promise;

  return promise;
}

async function fetchLatestTour(facilityId) {
  const tours = await fetchTours(facilityId, true);
  if (tours?.length > 0) {
    const maxTourId = Math.max(...(tours.map((t) => t.id)));
    const tour = tours.find((t) => t.id === maxTourId);
    return tour;
  }
  return null;
}

async function reserveTour({
  facilityId, tourSlotId, useParentSlots, comment, dependentIds,
}) {
  const { data } = await axios.post('/tour_reservations.json', {
    facility_id: facilityId,
    tour_slot_id: tourSlotId,
    use_parent_slots: useParentSlots,
    parent_comment: comment,
    dependent_ids: dependentIds,
  });

  const { successMessage, errorMessage, redirectUrl } = toCamelCase(data);

  if (successMessage) {
    const tour = await fetchLatestTour(facilityId);
    return { tour, redirectUrl };
  }
  throw new Error(errorMessage);
}

export {
  fetchParentSlots,
  updateParentSlots,
  reserveTour,
  fetchLatestTour,
  fetchTours,
};
