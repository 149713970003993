import { addArrayToDictionary, getBoundsFromFacilities, toCamelCase } from '@utils';
import { upperFirst } from 'lodash';
import {
  FACILITY_OPINIONS,
  searchFacilities,
  fetchFacilities,
  fetchFacilitiesByOpinion,
  fetchFacilitiesForProvider,
  fetchMatchesFacilitiesForParent,
  saveFacilityOpinion,
  saveFacilityComment,
  fetchOpinionCounts,
  saveFacilityImages,
  acceptPayment,
  deactivateFacilitiesPayment,
} from './facilities.service';

const searchSortOptionsMap = {
  partners: 'quality',
  distance: 'distance',
  price: 'price',
};

const shortlistSortOptionsMap = {
  fit: 'fit',
  distance: 'distance',
  price: 'price',
};

const matchesSortOptionsMap = {
  distance: 'distance',
  price: 'price',
};

function convertMapToRadioOptions(options) {
  const keys = Object.keys(options);
  return keys.map((k) => ({ label: k, value: options[k] }));
}

export default {
  namespaced: true,

  state: {
    storedFacilities: {},
    orderedResultIds: [],

    hoveredId: undefined,

    records: {
      ids: [],
      facilities: {},
    },

    opinionCounts: {
      favorited: undefined,
      saved: undefined,
      ignored: undefined,
    },

    comments: {},

    favoritedIds: [],
    ignoredIds: [],
    savedIds: [],
    matchesIds: [],

    loadedIds: {
      favorited: false,
      saved: false,
      ignored: false,
      matches: false,
    },

    facilitiesResultsForProvider: null,

    matchesSortOption: matchesSortOptionsMap.distance,
    shortlistSortOption: shortlistSortOptionsMap.fit,
    searchSortOption: searchSortOptionsMap.partners,
    selectedFacilityId: null,
  },

  getters: {
    currentSearchSortKey: (state) => () => state.searchSortOption,

    currentMatchesSortKey: (state) => () => state.matchesSortOption,

    currentShortlistSortKey: (state) => () => state.shortlistSortOption,

    orderedFacilities: (state) => {
      const facilities = state.records.ids
        .map((id) => state.records.facilities[id])
        .filter((f) => !!f && state.ignoredIds.indexOf(f.id) < 0);

      return {
        exact: facilities.filter((f) => f.exactMatch).map(({ id }) => id),
        partial: facilities.filter((f) => !f.exactMatch).map(({ id }) => id),
      };
    },

    resultsWithOffersCount: (state) => Object.values(state.records.facilities)
      .filter((f) => f.offersCount)
      .length,

    favoritedFacilities: (state) => state.favoritedIds
      .map((id) => state.storedFacilities[id])
      .filter((f) => !!f),

    ignoredFacilities: (state) => state.ignoredIds
      .map((id) => state.storedFacilities[id])
      .filter((f) => !!f),

    savedFacilities: (state) => state.savedIds
      .map((id) => state.storedFacilities[id])
      .filter((f) => !!f),

    shortlistFacilities: (state, getters) => getters.favoritedFacilities
      .concat(getters.savedFacilities),

    matchesFacilities: (state) => state.matchesIds
      .map((id) => state.storedFacilities[id])
      .filter((f) => !!f),

    sortedByDistance: (state, getters) => (facilities) => {
      const sortedFacilities = facilities.sort((a, b) => {
        const facilityA = getters.facilityById(a);
        const facilityB = getters.facilityById(b);
        return facilityA.distance - facilityB.distance;
      });
      return sortedFacilities;
    },

    sortedByPrice: (state, getters) => (facilities) => {
      const sortedFacilities = facilities.sort((a, b) => {
        const facilityA = getters.facilityById(a);
        const facilityB = getters.facilityById(b);

        if (facilityA.priceFrom === facilityB.priceFrom) return 0;
        if (facilityA.priceFrom === null) return 1;
        if (facilityB.priceFrom === null) return -1;
        return facilityA.priceFrom < facilityB.priceFrom ? -1 : 1;
      });
      return sortedFacilities;
    },

    sortedShortlistFacilities: (state, getters) => {
      const facilitiesByFit = state.favoritedIds.concat(state.savedIds);

      switch (state.shortlistSortOption) {
        case 'fit':
          return facilitiesByFit;
        case 'distance':
          return getters.sortedByDistance(facilitiesByFit);
        case 'price':
          return getters.sortedByPrice(facilitiesByFit);
        default:
          return facilitiesByFit;
      }
    },

    sortedMatchesFacilities: (state, getters) => {
      const facilities = state.matchesIds;

      switch (state.matchesSortOption) {
        case 'distance':
          return getters.sortedByDistance(facilities);
        case 'price':
          return getters.sortedByPrice(facilities);
        default:
          return facilities;
      }
    },

    areFavoritedIdsLoaded: (state) => state.loadedIds.favorited,

    areSavedIdsLoaded: (state) => state.loadedIds.saved,

    areIgnoredIdsLoaded: (state) => state.loadedIds.ignored,

    areShortlistIdsLoaded: (state) => state.loadedIds.favorited && state.loadedIds.saved,

    searchSortOptions: () => convertMapToRadioOptions(searchSortOptionsMap),

    matchesSortOptions: () => convertMapToRadioOptions(matchesSortOptionsMap),

    shortlistSortOptions: () => convertMapToRadioOptions(shortlistSortOptionsMap),

    opinionCounts: (state, getters) => {
      const shortlistIdsLength = state.favoritedIds.length + state.savedIds.length;

      return {
        favorited: (getters.areFavoritedIdsLoaded
          ? state.favoritedIds.length
          : state.opinionCounts.favorited)
          || 0,
        saved: (getters.areSavedIdsLoaded
          ? state.savedIds.length
          : state.opinionCounts.saved)
          || 0,
        ignored: (getters.areIgnoredIdsLoaded
          ? state.ignoredIds.length
          : state.opinionCounts.ignored)
          || 0,
        shortlisted: (getters.areShortlistIdsLoaded
          ? shortlistIdsLength
          : state.opinionCounts.shortlisted)
          || 0,
      };
    },

    facilityIsFavorited: (state) => (id) => state.favoritedIds.includes(id),

    facilityIsSaved: (state) => (id) => state.savedIds.includes(id),

    facilityIsIgnored: (state) => (id) => state.ignoredIds.includes(id),

    facilityById: (state) => (id) => state.records.facilities[id]
      || state.storedFacilities[id]
      || { id },

    totalMatchesFacilities: (state) => state.matchesIds.length || 0,
  },

  mutations: {
    setSelectedFacilityId(state, facilityId) {
      state.selectedFacilityId = facilityId;
      localStorage.setItem('selectedFacilityId', facilityId);
    },

    setFacilityRecords: (state, { facilities, ids }) => {
      state.records = {
        facilities: addArrayToDictionary(facilities, []),
        ids,
      };
    },

    setMatchesSortOption: (state, sort) => {
      state.matchesSortOption = sort || state.matchesSortOption;
    },

    setShortlistSortOption: (state, sort) => {
      state.shortlistSortOption = sort || state.shortlistSortOption;
    },

    setSearchSortOption: (state, sort) => {
      state.searchSortOption = sort || state.searchSortOption;
    },

    setMatchesIds: (state, ids) => {
      state.matchesIds = ids;
      state.loadedIds.matches = true;
    },

    setFavoritedIds: (state, ids) => {
      state.favoritedIds = ids;
      state.loadedIds.favorited = true;
    },

    setSavedIds: (state, ids) => {
      state.savedIds = ids;
      state.loadedIds.saved = true;
    },

    setIgnoredIds: (state, ids) => {
      state.ignoredIds = ids;
      state.loadedIds.ignored = true;
    },

    setOpinionCounts: (state, counts) => {
      state.opinionCounts = counts;
    },

    setOpinionState: (state, { facilityId, opinion }) => {
      ['favorited', 'saved', 'ignored'].forEach((key) => {
        state[`${key}Ids`] = state[`${key}Ids`].filter((ids) => ids !== facilityId);
      });
      if (opinion === FACILITY_OPINIONS.favorited) {
        state.favoritedIds.push(facilityId);
      } else if (opinion === FACILITY_OPINIONS.saved) {
        state.savedIds.push(facilityId);
      } else if (opinion === FACILITY_OPINIONS.ignored) {
        state.ignoredIds.push(facilityId);
      }
    },

    addStoredFacilities: (state, facilities) => {
      state.storedFacilities = { ...addArrayToDictionary(facilities, state.storedFacilities) };
      facilities.forEach((f) => {
        state.comments[f.id] = f.comment || '';
      });
    },

    setHoveredId(state, id) {
      state.hoveredId = id;
    },

    updateFacilityComment(state, { facilityId, comment }) {
      state.comments = { ...state.comments, [facilityId]: comment };
    },
    setAcceptPayment(state, { facilityId, acceptKinsidePayment }) {
      state.facilitiesResultsForProvider = {
        ...state.facilitiesResultsForProvider,
        facilities: state.facilitiesResultsForProvider.facilities.map((facility) => {
          if (facility.id === facilityId) {
            return {
              ...facility,
              acceptKinsidePayment,
            };
          }
          return facility;
        }),
      };
    },
    setDeactivatePayment(state) {
      state.facilitiesResultsForProvider = {
        ...state.facilitiesResultsForProvider,
        facilities: state.facilitiesResultsForProvider.facilities
          .map((facility) => ({ ...facility, acceptKinsidePayment: false })),
      };
    },
    setFacilitiesResultsForProvider(state, results) {
      state.facilitiesResultsForProvider = { ...results };
    },
  },

  actions: {
    async setSort({ commit, dispatch, rootGetters }, sort) {
      if (rootGetters.showShortlist) {
        await commit('setShortlistSortOption', sort);
      }
      if (rootGetters.showRecords) {
        await commit('setSearchSortOption', sort);
        await commit('search/updatePagination', { page: 1 }, { root: true });
        dispatch('search/reload', undefined, { root: true });
      }
      if (rootGetters.showMatches) {
        await commit('setMatchesSortOption', sort);
      }
    },

    async loadOpinionCounts({ commit }) {
      const opinionCounts = await fetchOpinionCounts();
      commit('setOpinionCounts', opinionCounts);
    },

    async loadFacilitiesByOpinion({
      commit, dispatch, state,
    }, opinion) {
      const opinionLabel = upperFirst(opinion);
      try {
        const noFacilitiesWithOpinion = state.opinionCounts[opinion] === 0;
        if (noFacilitiesWithOpinion) {
          return;
        }

        const loadedFacilitiesCount = state[`${opinion}Ids`].map((id) => state.storedFacilities[id]).filter(Boolean).length;
        const areFacilitiesLoaded = state.opinionCounts[opinion] !== undefined
          && state.opinionCounts[opinion] === loadedFacilitiesCount;

        if (areFacilitiesLoaded) {
          return;
        }

        const {
          ids,
          facilities,
        } = await fetchFacilitiesByOpinion(opinion);
        const bounds = getBoundsFromFacilities(facilities);
        await commit(`set${opinionLabel}Ids`, ids);
        await commit(`map/set${opinionLabel}Bounds`, bounds, { root: true });
        commit('addStoredFacilities', facilities);
      } catch (error) {
        dispatch('notifications/addToastError', { text: `Could not load the list of ${opinion} facilities`, error }, { root: true });
      }
    },

    async fetchFacilitiesToStore({ commit, dispatch }, { ids = [], limited = true } = {}) {
      try {
        if (ids.length === 0) {
          return;
        }
        const params = { idsToRequest: ids };
        const facilities = await fetchFacilities(params, { limited });
        commit('addStoredFacilities', facilities);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch and store facilities', error }, { root: true });
        throw new Error();
      }
    },

    async searchFacilities({ dispatch }, searchParams) {
      try {
        const records = await searchFacilities(searchParams);
        return records;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not run a search', error }, { root: true });
        return [];
      }
    },

    async toggleFavorited({
      dispatch, getters,
    }, { facilityId, context }) {
      const wasFavorited = getters.facilityIsFavorited(facilityId);
      const opinion = wasFavorited
        ? FACILITY_OPINIONS.noOpinion
        : FACILITY_OPINIONS.favorited;

      await dispatch('updateFacilityOpinion', { facilityId, context, opinion });
    },

    async toggleSaved({
      dispatch, getters,
    }, { facilityId, context }) {
      const wasSaved = getters.facilityIsSaved(facilityId);
      const opinion = wasSaved
        ? FACILITY_OPINIONS.noOpinion
        : FACILITY_OPINIONS.saved;

      await dispatch('updateFacilityOpinion', { facilityId, context, opinion });
    },

    async toggleIgnored({
      dispatch, getters,
    }, { facilityId, context }) {
      const wasIgnored = getters.facilityIsIgnored(facilityId);
      const opinion = wasIgnored
        ? FACILITY_OPINIONS.noOpinion
        : FACILITY_OPINIONS.ignored;

      await dispatch('updateFacilityOpinion', { facilityId, context, opinion });
    },

    async updateFacilityOpinion({
      commit, dispatch, getters, rootGetters,
    }, { facilityId, opinion, context }) {
      const facility = getters.facilityById(facilityId);
      const oldOpinion = facility.opinion;

      // change optimistically
      commit('setOpinionState', { opinion, facilityId });
      commit('addStoredFacilities', [facility]);
      try {
        await saveFacilityOpinion(facilityId, opinion, context);

        if (opinion === FACILITY_OPINIONS.favorited || opinion === FACILITY_OPINIONS.saved) {
          await dispatch(
            'notifications/addToastNotification',
            { text: 'Location added to your shortlist' },
            { root: true },
          );
        } else if (opinion === FACILITY_OPINIONS.ignored) {
          await dispatch(
            'notifications/addToastNotification',
            { text: 'Location moved to not a fit' },
            { root: true },
          );
        }

        // fetch matches so we only show matches
        // that do not have an opinion set
        if (rootGetters.showMatches) {
          // fetch matches
          await dispatch('fetchMatchesFacilitiesForParent');

          // check/set flag for matches note
          const hasSeenMatchesNote = localStorage.getItem('hasSeenMatchesNote');
          if (!hasSeenMatchesNote) {
            await commit('setHasSeenMatchesNote', true, { root: true });
            localStorage.setItem('hasSeenMatchesNote', true);
          }
        }

        // update bounds
        if (!rootGetters.showShortlist
          && (
            [opinion, oldOpinion].includes(FACILITY_OPINIONS.favorited)
            || [opinion, oldOpinion].includes(FACILITY_OPINIONS.saved)
          )
        ) {
          commit('map/setShortlistBounds', getBoundsFromFacilities(getters.shortlistFacilities), { root: true });
        }
        if (!rootGetters.showFavorited
          && [opinion, oldOpinion].includes(FACILITY_OPINIONS.favorited)
        ) {
          commit('map/setFavoritedBounds', getBoundsFromFacilities(getters.favoritedFacilities), { root: true });
        }
        if (!rootGetters.showSaved && [opinion, oldOpinion].includes(FACILITY_OPINIONS.saved)) {
          commit('map/setSavedBounds', getBoundsFromFacilities(getters.savedFacilities), { root: true });
        }
        if (!rootGetters.showIgnored && [opinion, oldOpinion].includes(FACILITY_OPINIONS.ignored)) {
          commit('map/setIgnoredBounds', getBoundsFromFacilities(getters.ignoredFacilities), { root: true });
        }
      } catch (error) {
        commit('setOpinionState', { opinion: oldOpinion, facilityId });
        dispatch('notifications/addToastError', { text: 'Could not update opinion', error }, { root: true });
      }
    },

    async updateComment({
      commit, dispatch, getters,
    }, { facilityId, comment, context }) {
      const facility = getters.facilityById(facilityId);

      try {
        await saveFacilityComment(facilityId, comment);
        commit('updateFacilityComment', { facilityId, comment });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not save a comment', error }, { root: true });
      }
      if (facility.friendlyId) {
        dispatch('track/event', {
          category: 'parents',
          event: 'add_location_note',
          props: {
            slug: `/childcare/${facility.friendlyId}`,
            context,
            opinion: facility.opinion,
          },
        }, { root: true });
      }
      return comment || facility?.comment;
    },
    // eslint-disable-next-line
    async saveFacilityImages({ commit }, { id, data }) {
      await saveFacilityImages(id, data);
    },

    async fetchFacilitiesForProvider(
      { commit, dispatch, rootState },
      { page = 1, search = null, messaging },
    ) {
      try {
        const results = await fetchFacilitiesForProvider({
          providerId: rootState.providerId,
          page,
          search,
          messaging,
        });
        commit('setFacilitiesResultsForProvider', results);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Something went wrong fetching facilities' }, { root: true });
      }
    },

    async fetchMatchesFacilitiesForParent({
      commit,
      dispatch,
      getters,
    }) {
      const matchesData = await fetchMatchesFacilitiesForParent();
      const facilityIds = matchesData.recommendations
        .map((facility) => parseInt(Object.keys(facility)[0], 10));
      await commit('setMatchesPhase', matchesData.empty_state, { root: true });
      await commit('setMatchesIds', facilityIds);
      await dispatch('fetchFacilitiesToStore', { ids: facilityIds, limited: false });
      const bounds = getBoundsFromFacilities(getters.matchesFacilities);
      await commit('map/setMatchesBounds', bounds, { root: true });
    },

    newConversationReceived({ rootState, state }, conv) {
      const { conversation, newMessage } = toCamelCase(conv);
      const userType = rootState.user.current.isParent ? 'User' : 'Provider';
      if (userType === newMessage.senderType) return;
      const facility = state.facilitiesResultsForProvider.facilities
        .find((f) => f.id === conversation.childCareFacilityId);
      facility.hasUnreadMessages = true;
    },

    async acceptPayment(
      { commit, dispatch },
      { facilityId, acceptKinsidePayment },
    ) {
      await acceptPayment(facilityId, acceptKinsidePayment);
      commit('setAcceptPayment', { facilityId, acceptKinsidePayment });

      if (acceptKinsidePayment) {
        dispatch('notifications/addToastNotification', { text: 'Location payment link activated' }, { root: true });
      } else {
        dispatch('notifications/addToastNotification', { text: 'Location payment link deactivated' }, { root: true });
      }
    },
    async deactivateFacilitiesPayment({ commit, dispatch }) {
      await deactivateFacilitiesPayment();
      commit('setDeactivatePayment');
      dispatch('notifications/addToastNotification', { text: 'Deactivated payment link for all locations' }, { root: true });
    },
  },
};
