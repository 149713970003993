import vue from 'vue';
import moment from 'moment-timezone';
import { addArrayToDictionary } from '@utils';
import {
  fetchParentSlots, updateParentSlots, reserveTour, fetchTours,
} from './tours.service';

export default {
  namespaced: true,
  state: {
    parentSlots: [],
    tours: {},
    toursAsArray: [],
  },
  mutations: {
    setParentSlots: (state, { slots }) => {
      state.parentSlots = [...slots];
    },
    setTours: (state, payload) => {
      state.tours = { ...addArrayToDictionary(payload, state.tours) };
    },
    setToursAsArray: (state, payload) => {
      state.toursAsArray = [...payload];
    },
  },
  getters: {
    tourStartTime: (state) => (tourId, format = 'ddd MMM D h:mma') => {
      const tour = state.tours[tourId];
      const startTime = moment.unix(tour.startAt).tz(tour.timezone);
      return startTime.format(format);
    },
  },
  actions: {
    async fetchParentSlots({ commit, dispatch }, { timezone }) {
      try {
        const slots = await fetchParentSlots(timezone);
        commit('setParentSlots', { slots });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load parent slots', error }, { root: true });
      }
    },

    async fetchTours({ commit, rootState }) {
      const tours = await fetchTours(rootState.facilityId);
      commit('setTours', tours);
      commit('setToursAsArray', tours);
    },

    async updateParentSlots({ commit }, { newSlots, timezone }) {
      const slots = newSlots.map((ps) => {
        const date = vue.moment(ps.date).format('YYYY-MM-DD');
        const slotDateFrom = vue.moment
          .tz(`${date} ${ps.time.from}`, 'YYYY-MM-DD h:mm A', timezone)
          .utc();
        const slotDateTo = vue.moment
          .tz(`${date} ${ps.time.to}`, 'YYYY-MM-DD h:mm A', timezone)
          .utc();

        return {
          id: ps.id,
          from: slotDateFrom,
          to: slotDateTo,
          date: ps.date,
        };
      });
      const updatedSlots = await updateParentSlots(slots);
      commit('setParentSlots', { slots: updatedSlots });
    },

    async reserveTour({ commit, dispatch, rootState }, { tourData, facilityId }) {
      try {
        const { tour, redirectUrl } = await reserveTour(
          {
            ...tourData,
            facilityId,
            dependentIds: Object.keys(rootState.dependents.list),
          },
        );
        if (tour) {
          commit('setTours', [tour]);
        }
        return { redirectUrl, tour };
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not reserve the tour', error }, { root: true });
        throw new Error();
      }
    },
  },
};
