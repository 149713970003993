import { axios, toCamelCase, getEnvVar } from '@utils';

const API_URL = getEnvVar('LEGUP_API_URL');
const API_KEY = getEnvVar('LEGUP_API_KEY');
const FETCH_OPTIONS = {
  method: 'GET',
  headers: {
    'X-LegUp-Signature': API_KEY,
  },
};

let fetchFamilyStatusPromise;
async function fetchFamilyStatus(email) {
  if (fetchFamilyStatusPromise) {
    return fetchFamilyStatusPromise;
  }

  const url = `${API_URL}/family/status?email=${email}`;

  const promise = fetch(url, FETCH_OPTIONS)
    .then((response) => response.json())
    .then((data) => {
      fetchFamilyStatusPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchFamilyStatusPromise = null;
      throw new Error(error);
    });

  fetchFamilyStatusPromise = promise;

  return promise;
}

let fetchCenterDirectoryPromise;
async function fetchCenterDirectory(centerIds) {
  if (fetchCenterDirectoryPromise) {
    return fetchCenterDirectoryPromise;
  }

  const url = `${API_URL}/center/directory?cids=${centerIds}`;

  const promise = fetch(url, FETCH_OPTIONS)
    .then((response) => response.json())
    .then((data) => {
      fetchCenterDirectoryPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchCenterDirectoryPromise = null;
      throw new Error(error);
    });

  fetchCenterDirectoryPromise = promise;

  return promise;
}

let dependentAgeGroupPromise;
async function dependentAgeGroup(centerId, childBirthDate) {
  if (dependentAgeGroupPromise) {
    return dependentAgeGroupPromise;
  }

  const promise = axios.get(
    '/api/v1/legup/tours/age_group',
    {
      params: {
        legup_center_id: centerId,
        birth_date: childBirthDate,
      },
    },
  )
    .then(({ data }) => {
      dependentAgeGroupPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      dependentAgeGroupPromise = null;
      throw new Error(error);
    });

  dependentAgeGroupPromise = promise;

  return promise;
}

async function bookTour(centerId, parent, child, tourInfo, noFamilyNotes) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/schedule', {
      legup_center_id: centerId,
      parent,
      child,
      tour_info: tourInfo,
      no_family_notes: noFamilyNotes,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function rescheduleTour(tour, newDateTime) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/reschedule', {
      legup_center_id: tour.centerId || tour.center.centerId,
      tour,
      new_date_time: newDateTime,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function cancelTour(tour) {
  try {
    const { data } = await axios.post('/api/v1/legup/tours/cancel', {
      legup_center_id: tour.centerId || tour.center.centerId,
      id: tour.tourId,
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

let fetchUpcomingParentTourForCenterPromise;
async function fetchUpcomingParentTourForCenter(parentId, legupCenterId) {
  if (fetchUpcomingParentTourForCenterPromise) {
    return fetchUpcomingParentTourForCenterPromise;
  }

  const promise = axios.get('/api/v1/legup/tours', {
    params: {
      parent_id: parentId,
      legup_center_id: legupCenterId,
    },
  })
    .then(({ data }) => {
      fetchUpcomingParentTourForCenterPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchUpcomingParentTourForCenterPromise = null;
      throw new Error(error);
    });
  fetchUpcomingParentTourForCenterPromise = promise;

  return promise;
}

let fetchToursForParentPromise;
async function fetchToursForParent(parentId) {
  if (fetchToursForParentPromise) {
    return fetchToursForParentPromise;
  }

  const promise = axios.get('/api/v1/legup/tours', {
    params: {
      parent_id: parentId,
    },
  })
    .then(({ data }) => {
      fetchToursForParentPromise = null;
      return toCamelCase(data);
    })
    .catch((error) => {
      fetchToursForParentPromise = null;
      throw new Error(error);
    });
  fetchToursForParentPromise = promise;

  return promise;
}

async function centerTourPolicy(centerId) {
  try {
    const { data } = await axios.get(`/api/v1/legup/tours/policy?legup_center_id=${centerId}`);
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function tourProviderSetting(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/tours/tours_enabled', {
      params: {
        legup_center_id: params.centerId,
        place: params.place,
      },
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

async function centerTourSlots(params) {
  try {
    const { data } = await axios.get('/api/v1/legup/tours/slots', {
      params: {
        legup_center_id: params.centerId,
        legup_age_group_id: params.ageGroupId,
        year: params.year,
        month: params.month,
      },
    });
    return toCamelCase(data);
  } catch (error) {
    throw new Error(error);
  }
}

let providerWaitlistFeesPromise;
async function providerWaitlistFees(params) {
  if (providerWaitlistFeesPromise) {
    return providerWaitlistFeesPromise;
  }

  const promise = axios.get('/api/v1/legup/centers/waitlist_fee.json', { params })
    .then(({ data }) => {
      providerWaitlistFeesPromise = null;
      return data;
    })
    .catch(() => {
      providerWaitlistFeesPromise = null;
    });

  providerWaitlistFeesPromise = promise;

  return promise;
}

async function validateProviderWaitlistCoupon(params) {
  const { data } = await axios.get('/api/v1/legup/coupons/validate_code_and_calculate_discount.json', { params });

  return data;
}

async function checkForProviderQuestionsDiscount(params) {
  const { data } = await axios.post('/api/v1/legup/coupons/check_coupon_questions_for_automatic_discount.json', { params });

  return data;
}

async function checkForProviderSubsidiesDiscount(params) {
  const { data } = await axios.post(' /api/v1/legup/coupons/check_coupons_for_automatic_subsidy_discount.json', { params });

  return data;
}

let findCenterInfoPromise;
async function findCenterInfo(legupCenterId) {
  if (findCenterInfoPromise) {
    return findCenterInfoPromise;
  }

  const promise = axios.get(`/api/v1/legup/centers/${legupCenterId}.json`)
    .then(({ data }) => {
      findCenterInfoPromise = null;
      return data;
    })
    .catch(() => {
      findCenterInfoPromise = null;
    });

  findCenterInfoPromise = promise;

  return promise;
}

let findCenterQuestionsPromise;
async function findCenterQuestions(params) {
  if (findCenterQuestionsPromise) {
    return findCenterQuestionsPromise;
  }

  const promise = axios.get('/api/v1/legup/centers/find_questions.json', { params })
    .then(({ data }) => {
      findCenterQuestionsPromise = null;
      return data;
    })
    .catch(() => {
      findCenterQuestionsPromise = null;
    });

  findCenterQuestionsPromise = promise;

  return promise;
}

let findCenterSubsidiesPromise;
async function findCenterSubsidies(params) {
  if (findCenterSubsidiesPromise) {
    return findCenterSubsidiesPromise;
  }

  const promise = axios.get('/api/v1/legup/subsidies.json', { params })
    .then(({ data }) => {
      findCenterSubsidiesPromise = null;
      return data;
    })
    .catch(() => {
      findCenterSubsidiesPromise = null;
    });

  findCenterSubsidiesPromise = promise;

  return promise;
}

let validateSubsidiesDiscountPromise;
async function validateSubsidiesDiscount(centerId) {
  if (validateSubsidiesDiscountPromise) {
    return validateSubsidiesDiscountPromise;
  }

  const promise = axios.get(`/api/v1/legup/centers/${centerId}/validate_subsidy_discount`)
    .then(({ data }) => {
      validateSubsidiesDiscountPromise = null;
      return data;
    })
    .catch(() => {
      validateSubsidiesDiscountPromise = null;
    });

  validateSubsidiesDiscountPromise = promise;

  return promise;
}

let checkForProviderCouponsPromise;
async function checkForProviderCoupons(params) {
  if (checkForProviderCouponsPromise) {
    return checkForProviderCouponsPromise;
  }

  const promise = axios.get('/api/v1/legup/coupons/provider_coupons.json', { params })
    .then(({ data }) => data);

  checkForProviderCouponsPromise = promise;

  return promise;
}

async function updateOrRemoveWaitlistSpot(params) {
  const spotId = params.waitlist_spot_id;

  const { data } = await axios.post(`/api/v1/legup/waitlist_spots/${spotId}/update_or_remove`, { params });

  return data;
}

async function passOrDeclineSeatOffer(params) {
  const seatId = params.seat_id;

  const { data } = await axios.post(`/api/v1/legup/seat_offers/${seatId}/pass_or_decline.json`, params);

  return data;
}

async function updateWaitlistSpotAnswers(spotId, answers) {
  const { data } = await axios.post(`/api/v1/legup/waitlist_spots/${spotId}/update_answers`, { answers });

  return data;
}

async function ageGroupProgramSchedules(params) {
  const { data } = await axios.get('/api/v1/legup/age_group_program_schedules.json', { params });

  return toCamelCase(data);
}

async function fetchSeatOfferDetails(seatId) {
  const { data } = await axios.get(`/api/v1/legup/seat_offers/${seatId}`);

  return toCamelCase(data);
}

async function fetchSeatOffersForParent(params) {
  const { data } = await axios.get('/api/v1/legup/seat_offers', { params });

  return toCamelCase(data);
}

async function sendOffersMessage(seatId) {
  const { data } = await axios.post(`/api/v1/legup/seat_offers/${seatId}/message_provider`);

  return toCamelCase(data);
}

async function requestPaperwork(seatId) {
  const { data } = await axios.post(`/api/v1/legup/seat_offers/${seatId}/request_paperwork`);

  return toCamelCase(data);
}

async function acceptSeatOffer({ seatId, paymentRequestMethods }) {
  const { data } = await axios.post(`/api/v1/legup/seat_offers/${seatId}/accept`, { payment_request_methods: paymentRequestMethods });

  return toCamelCase(data);
}

export {
  fetchFamilyStatus,
  fetchCenterDirectory,
  dependentAgeGroup,
  bookTour,
  cancelTour,
  fetchUpcomingParentTourForCenter,
  fetchToursForParent,
  tourProviderSetting,
  centerTourPolicy,
  centerTourSlots,
  rescheduleTour,
  providerWaitlistFees,
  validateProviderWaitlistCoupon,
  findCenterInfo,
  findCenterQuestions,
  findCenterSubsidies,
  checkForProviderQuestionsDiscount,
  checkForProviderSubsidiesDiscount,
  validateSubsidiesDiscount,
  checkForProviderCoupons,
  updateOrRemoveWaitlistSpot,
  passOrDeclineSeatOffer,
  updateWaitlistSpotAnswers,
  ageGroupProgramSchedules,
  fetchSeatOfferDetails,
  fetchSeatOffersForParent,
  sendOffersMessage,
  requestPaperwork,
  acceptSeatOffer,
};
